import React, { ReactElement } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { TestMakerLayout } from '../../containers';
import dynamic from 'next/dynamic';

const SpaceObjectsContainerDynamic = dynamic<{ selectedFolderId?: string }>(
  () =>
    import('../../containers/space/space-objects.container').then(
      (mod) => mod.SpaceObjectsContainer,
    ),
  {
    ssr: false,
  },
);
const SpacePage = () => {
  return <SpaceObjectsContainerDynamic selectedFolderId="home" />;
};

export const getStaticProps = async (context: any) => {
  const { locale } = context;
  if (locale === undefined) {
    throw new Error('locale is missing');
  }
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
};

SpacePage.getLayout = function getLayout(page: ReactElement) {
  return <TestMakerLayout>{page}</TestMakerLayout>;
};

export default SpacePage;
